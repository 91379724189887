import { combineReducers } from "redux";
import mainReducer from "@/bit/reducers/reducers.main-reducer";
import modalsReducer from "@/bit/reducers/reducers.modals-reducer";
import headerReducer from "@/bit/reducers/reducers.header-reducer";
import trackingReducer from '@/bit/reducers/reducers.tracking-reducer';

const REDUCERS_LIST = {
  main: mainReducer,
  modals: modalsReducer,
  header: headerReducer,
  tracking:trackingReducer
};

const rootReducers = () =>
  combineReducers({
    ...REDUCERS_LIST,
  });

export default rootReducers;
