import { call } from "redux-saga/effects";

let getListTrackingModule = null;
export function* getListTracking({ pageNumber, update }) {
  if (!getListTrackingModule) {
    getListTrackingModule = yield call(() => import('./get-list-tracking'));
  }
  //llamada a modulo
  yield call(getListTrackingModule.getListTracking, { pageNumber, update })
}

let getDetailTrackingModule = null;
export function* getDetailTracking({ handlingCode = 0, co }) {
  if(!getDetailTrackingModule){
    getDetailTrackingModule = yield call(() => import('./get-detail-tracking'))
  }
  yield call(getDetailTrackingModule.getDetailTracking, {handlingCode, co })
  // llamada a modulo
}
let getSlotsTrackingModule = null;
export function* getSlotsTracking({ handlingCode = 0, id }) {
  if(!getSlotsTrackingModule){
    getSlotsTrackingModule = yield call(() => import('./get-slots-tracking'))
  }
  yield call(getSlotsTrackingModule.getSlotsTracking, { handlingCode, id })
  //llamada modulo
}
let postModifyAppointmentModule = null;
export function* postModifyAppointment({ handlingCode = 0, payload }) {
  if(!postModifyAppointmentModule){
    postModifyAppointmentModule = yield call(() => import('./post-modify-appointment'))
  }
  yield call(postModifyAppointmentModule.postModifyAppointment, {handlingCode, payload})
  //llamada modulo
}
let getOrderDetailsModule = null;
export function* getOrderDetails({payload}) {
  const { handlingCode = 0, customerOrderCode, logged } = payload
  if(!getOrderDetailsModule){
    getOrderDetailsModule = yield call(() => import('./get-order-details'))
  }
  yield call(getOrderDetailsModule.getOrderDetails, {handlingCode, customerOrderCode, logged})
  // llamada modulo
}
