import React from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import shouldLoadDyna from "@/bit/core/core.utils.should-load-dyna";
import RouteHandler from "@/bit/core/core.route-handler";
import WebviewBridge from "@/bit/core/core.scripts.webview-bridge";
import Saam from "@/bit/core/core.scripts.saam";
import OneTrust from "@/bit/components/components.one-trust";
import ErrorBoundary from "@/component/error-boundary";
import { wrapper } from "store";
import "@/styles/globals.scss";
import "@/styles/skeleton.scss";

 /* [STYLES] begins */ 
 import '@/styles/globals.scss'; 
 import '@/styles/grid.scss'; 
 import '@/styles/colors.scss'; 
 import '@/styles/fonts.scss';
 
 /* [STYLES] ends */ 

import "@/styles/card.scss";
import "@/styles/image-slider.scss";
import "@/styles/tv-card.scss";
import "@/styles/product-modal.scss";
import "@/styles/tv-filters.scss";
import "@/styles/guide-list.scss";
import "@/styles/alert.scss";

const Dyna = dynamic(() => import("@/bit/core/core.scripts.dyna"));

const App = ({ Component, pageProps }) => {
  const Modal = useSelector((state) => state.modals.Modal);
  return (
    <>
      <WebviewBridge />
      <RouteHandler />
      <ErrorBoundary>
        <Component {...pageProps} />
        {Modal && Modal}
        <OneTrust />
      </ErrorBoundary>
      <Saam />
      {shouldLoadDyna() && <Dyna />}
      {/* APP DELIMITER */}
    </>
  );
};

export default wrapper.withRedux(App);
